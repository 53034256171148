export const normalizeText = (text: string) => {
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
}

export const handlePlural = (text: string, language: 'PtBr' | 'EnUs') => {
  if (language === 'EnUs') {
    return text.endsWith('s') ? text.slice(0, -1) : text
  } else if (language === 'PtBr') {
    if (text.endsWith('s')) {
      return text.slice(0, -1)
    } else if (text.endsWith('ões')) {
      return text.slice(0, -3) + 'ão'
    } else if (text.endsWith('ães')) {
      return text.slice(0, -2) + 'ão'
    }
  }
  return text
}

export const encodeQueryString = (query: string): string => {
  return encodeURIComponent(query)
}
