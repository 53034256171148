export { default as darken } from './darken'
export { default as classNames } from './classNames'
export {
  formatData,
  formatTimeDistance,
  formatDate,
  getFutureDate,
  isDateValid
} from './date'
export type { FormatType, LocaleType } from './date'
export { generateColor, isColorDark, hexToRgba } from './colors'
export { getInitialValuesFromItems } from './getInitialValuesFromItems'
export { createMark, createOrUpdateMark, getSignMarks } from './envelope'
export { default as trimCanvas } from './trimCanvas'
export { default as formatCurrency } from './currency'
export { createLinkWithParams } from './link'
export { isValidUUID } from './id'
export {
  getFromLocalStorage,
  setInLocalStorage,
  removeFromLocalStorage
} from './localStorage'
export { normalizeText, handlePlural, encodeQueryString } from './text'
export { stringify, parse } from './queryString'
export { validateCPF, validateCNPJ } from './validations'
