import { v4 as uuidv4 } from 'uuid'
import { HEIGHT_MARK, WIDTH_MARK } from '@/constants/sizesPdf'
import {
  DocumentEnvelope,
  SignerEnvelopeResponse
} from '@/hooks/api/ecm/useEnvelopeApi/useEnvelopeApi.types'
import { SignMarkWithSignerType } from '@/ui/molecules/DocumentSignaturePlacement/DocumentSignaturePlacement.types'

export const createMark = (
  e: any,
  page: number,
  selectedSigner: string | null,
  type = 'Signature',
  scaleFactor: number
) => {
  const rect = e.target.getBoundingClientRect()
  const markSize = {
    width: WIDTH_MARK,
    height: HEIGHT_MARK
  }

  let newMarkX = (e.clientX - rect.left) / scaleFactor
  let newMarkY = (e.clientY - rect.top) / scaleFactor

  newMarkX = Math.max(
    0,
    Math.min(newMarkX, rect.width / scaleFactor - markSize.width)
  )
  newMarkY = Math.max(
    0,
    Math.min(newMarkY, rect.height / scaleFactor - markSize.height)
  )

  return {
    id: 'fake-id-' + uuidv4(),
    page: page,
    type: type,
    x: parseFloat(newMarkX.toFixed(2)),
    y: parseFloat(newMarkY.toFixed(2)),
    signer: selectedSigner
  }
}

export const createOrUpdateMark = (
  document: DocumentEnvelope | null,
  mark: {
    id?: string
    type?: string
    page?: number
    x?: number
    y?: number
    rotation?: number
  }
) => {
  return {
    id: mark?.id || 'fake-id-' + uuidv4(),
    documentId: document?.id,
    type: mark?.type,
    page: mark?.page,
    x: mark?.x,
    y: mark?.y,
    rotation: mark?.rotation
  }
}

export const getSignMarks = (
  signers: SignerEnvelopeResponse[],
  documentId: string
): SignMarkWithSignerType[] => {
  const signMarksWithSignerData: SignMarkWithSignerType[] = []

  signers?.forEach(
    (signer) =>
      signer?.signMarks?.forEach((signMark) => {
        const signMarkWithSignerData = {
          id: signMark.id,
          type: signMark.type,
          documentId: signMark.documentId,
          page: signMark.page,
          x: signMark.x,
          y: signMark.y,
          rotation: signMark.rotation,
          signer: {
            id: signer.id,
            name: signer.name,
            email: signer.email,
            role: signer.role,
            title: signer.title,
            status: signer.status
          }
        }
        signMarksWithSignerData.push(
          signMarkWithSignerData as SignMarkWithSignerType
        )
      })
  )

  const data = signMarksWithSignerData
    .map((signMark) => {
      return {
        ...signMark
      }
    })
    .filter((signMark) => String(signMark.documentId) === String(documentId))

  return data
}
