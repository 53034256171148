'use client'

import React from 'react'
import * as SwitchPrimitive from '@radix-ui/react-switch'
import { cn } from '@/lib/utils'

type SwitchProps = {
  size?: 'sm' | 'md' | 'lg'
  checked?: boolean
  onChange?: (checked: boolean) => void
  onBlur?: () => void
  label?: string
  name: string
  defaultChecked?: boolean
  fullWidth?: boolean
  helperInfoNode?: React.ReactNode
  disabled?: boolean
  className?: string
  showSkeleton?: boolean
}

const sizeClasses = {
  sm: 'h-[16px] w-[32px]',
  md: 'h-[20px] w-[40px]',
  lg: 'h-[24px] w-[48px]'
}

const sizeClassesThumb = {
  sm: 'h-[10px] w-[10px]',
  md: 'h-[16px] w-[16px]',
  lg: 'h-[20px] w-[20px]'
}

const Toggle = ({
  size = 'md',
  checked,
  onChange,
  label,
  name,
  defaultChecked,
  fullWidth,
  helperInfoNode,
  disabled = false,
  className,
  showSkeleton = false,
  onBlur,
  ...props
}: SwitchProps) => {
  if (showSkeleton) {
    return (
      <div className="flex items-center w-full gap-1">
        <div className="w-10 h-5 md:h-4 skeleton" />
        <div className="w-20 h-5 skeleton" />
      </div>
    )
  }
  const id = `toggle-${name}`

  return (
    <div
      className={cn(
        'flex gap-2 items-center',
        fullWidth ? 'w-full' : '',
        className
      )}
    >
      <SwitchPrimitive.Root
        id={id}
        checked={checked}
        onCheckedChange={onChange}
        defaultChecked={defaultChecked}
        className={cn(
          'group',
          'radix-state-checked:bg-primary dark:radix-state-checked:bg-primary  radix-state-unchecked:bg-gray-200 relative inline-flex flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring focus-visible:ring-primary focus-visible:ring-opacity-50',
          sizeClasses[size],
          disabled
            ? 'opacity-50 cursor-not-allowed'
            : 'cursor-pointer hover:radix-state-checked:bg-primary-700 dark:hover:radix-state-checked:bg-primary-700 hover:radix-state-unchecked:bg-gray-300'
        )}
        name={name}
        disabled={disabled}
        onBlur={onBlur}
      >
        <SwitchPrimitive.Thumb
          className={cn(
            'group-radix-state-checked:translate-x-5',
            'group-radix-state-unchecked:translate-x-0',
            'pointer-events-none inline-block transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out',
            sizeClassesThumb[size]
          )}
        />
      </SwitchPrimitive.Root>
      {label && (
        <label className="flex flex-wrap w-full gap-1" htmlFor={id}>
          <div className="flex items-center text-sm font-medium leading-tight text-gray-700 w-max">
            {label}
          </div>
          <div className="flex w-max">
            {helperInfoNode ? helperInfoNode : null}
          </div>
        </label>
      )}
    </div>
  )
}

export default Toggle
